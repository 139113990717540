<template>
  <b-card no-body class="my-2">
    <b-card-header>
      Movimientos

      <div class="w-100 d-flex justify-content-end align-items-center">
        <div class="dropdown no-caret">
          <button
            class="btn btn-transparent-dark btn-icon dropdown-toggle"
            type="button"
            data-toggle="dropdown"
          >
            <i data-feather="more-vertical"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right animated--fade-in-up">
            <a class="dropdown-item my-cursor">
              Recargar
            </a>
          </div>
        </div>
      </div>
    </b-card-header>

    <b-card-body class="p-1">
      <b-table
        :items="getMovimientosOfCurrentTraspaso"
        :fields="fields"
        show-empty
        responsive
        sticky-header
        style="height: 300px"
      >
        <template #cell(importe)="data">
          <div class="text-primary text-center text-sm w-100">
            <b-badge variant="primary" class="ml-2">{{ $formatNumber(data.item.importe) }}</b-badge>
          </div>
        </template>
      </b-table>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MovimientosList',

  props: {
    traspaso: {
      type: Object,
      default: () => ({})
    }
  },

  created () {
    this.$store.commit('traspasoModule/setCurrentTraspaso', [])

    //
    // Obtiene el item desde un dispatch
    this.$store.dispatch('traspasoModule/getById', this.traspaso.id)
  },

  data () {
    return {
      fields: ['unidad_administrativa', 'proyecto', 'partida', 'fuente_financiamiento', 'importe']
    }
  },

  computed: {
    ...mapGetters('traspasoModule', ['getMovimientosOfCurrentTraspaso'])
  }
}
</script>
